var __typeError = (msg) => {
  throw TypeError(msg);
};
var __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg);
var __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj));
var __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
var __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), setter ? setter.call(obj, value) : member.set(obj, value), value);
var _handleClickAway;
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  constructor() {
    super(...arguments);
    __privateAdd(this, _handleClickAway);
  }
  connect() {
    __privateSet(this, _handleClickAway, (event) => this.handleClickAway(event));
  }
  on() {
    this.element.open = true;
  }
  off() {
    this.element.open = false;
  }
  toggled(_event) {
    if (this.element.open) {
      window.addEventListener("click", __privateGet(this, _handleClickAway));
    } else {
      window.removeEventListener("click", __privateGet(this, _handleClickAway));
    }
  }
  handleClickAway(event) {
    if (this.element.contains(event.target)) return;
    this.off();
  }
}
_handleClickAway = new WeakMap();
