var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { Controller } from "@hotwired/stimulus";
export default class TableSearchController extends Controller {
  search(event) {
    const term = event.target.value.toLocaleLowerCase();
    const rows = this.rowTargets;
    for (let i = rows.length - 1; i >= 0; i--) {
      const row = rows[i];
      const hidden = term !== "" && !row.dataset.search.toLocaleLowerCase().includes(term);
      row.hidden = hidden;
    }
  }
}
__publicField(TableSearchController, "targets", ["row"]);
