var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import AutoSubmitFormController from "./auto_submit_form_controller";
export default class product_association_controller_default extends AutoSubmitFormController {
  get form() {
    return this.element.querySelector("form");
  }
  submit(event) {
    super.submit(event);
    const [_systemCode, name] = event.target.selectedOptions[0].text.split(" - ");
    this.nameCellTarget.innerText = name ?? "";
  }
}
__publicField(product_association_controller_default, "targets", ["nameCell"]);
