var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { Controller } from "@hotwired/stimulus";
export default class toggle_controller_default extends Controller {
  connect() {
    if (this.performOnConnectValue) this.perform();
  }
  perform() {
    if (this.hasElementTarget) {
      this.elementTargets.forEach(this._toggleActiveClass);
    } else {
      this._toggleActiveClass(this.element);
    }
  }
  on() {
    if (this.hasElementTarget) {
      this.elementTargets.forEach((element) => this._toggleActiveClass(element, true));
    } else {
      this._toggleActiveClass(this.element, true);
    }
  }
  off() {
    if (this.hasElementTarget) {
      this.elementTargets.forEach((element) => this._toggleActiveClass(element, false));
    } else {
      this._toggleActiveClass(this.element, false);
    }
  }
  _toggleActiveClass(element, direction = !element.classList.contains(this.activeClass)) {
    setTimeout(() => {
      element.classList.toggle(this.activeClass, direction);
    }, 0);
  }
}
__publicField(toggle_controller_default, "targets", ["element"]);
__publicField(toggle_controller_default, "classes", ["active"]);
__publicField(toggle_controller_default, "values", {
  performOnConnect: {
    type: Boolean,
    default: false
  }
});
