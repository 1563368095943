var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select/dist/esm/tom-select";
export default class tom_select_controller_default extends Controller {
  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, {
      ...this.baseSettings,
      ...this.apiSettings
    });
    this.tomSelect.on("blur", () => {
      this.element.dispatchEvent(new Event("blur", { bubbles: true }));
    });
  }
  disconnect() {
    this.tomSelect.destroy();
  }
  async loadFunction(query, callback) {
    const url = `${this.urlValue}?query=${encodeURIComponent(query)}`;
    const response = await fetch(url, this.fetchConfig);
    const json = await response.json();
    callback(json);
  }
  get baseSettings() {
    const settings = {
      create: false,
      valueField: this.valueFieldValue,
      labelField: this.labelFieldValue,
      searchField: this.searchFieldValue
    };
    if (this.dropdownTarget.multiple) {
      settings.maxItems = this.dropdownTarget.dataset?.maxitems ?? null;
    }
    return settings;
  }
  get apiSettings() {
    if (this.hasUrlValue) {
      return { load: this.loadFunction.bind(this) };
    }
    return {};
  }
  get fetchConfig() {
    return {
      headers: { Accept: "application/json" }
    };
  }
}
__publicField(tom_select_controller_default, "targets", ["dropdown"]);
__publicField(tom_select_controller_default, "values", {
  url: String,
  valueField: {
    type: String,
    default: "id"
  },
  labelField: {
    type: String,
    default: "name"
  },
  searchField: {
    type: Array,
    default: ["name"]
  }
});
